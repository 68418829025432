import { Component, createSignal } from 'solid-js'
import styles from '~/components/series-collaborator-entry/series-collaborator-entry.module.scss'
import SmallButton from '~/components/small-button/small-button'
import { SeriesCollaboratorEntryProps } from '~/components/series-collaborator-entry/series-collaborator-entry.interfaces'
import { Role } from '~/services/database/permissions'
import Modal from '~/components/modal/modal'
import SeriesCollaboratorEditForm from '~/components/series-collaborator-edit-form/series-collaborator-edit-form'
import SeriesCollaboratorDelete from '~/components/series-collaborator-delete/series-collaborator-delete'
import { getUserDisplayName, user } from '~/services/database/users'
import { translations } from '~/translations'

const SeriesCollaboratorEntry: Component<SeriesCollaboratorEntryProps> = ({ entry, onUpdate, canUpdate }) => {
  const isSelf = entry.userId === user()?.id
  const role = new Role(entry.permission)
  const roleClasses = [styles.role, styles[role.value]].join(' ')
  const [showModal, setModal] = createSignal(false)
  const canDelete = () => (canUpdate && !isSelf) || (isSelf && role.value !== 'admin')
  const displayName = getUserDisplayName(entry)

  const onEdit = () => {
    setModal(true)
  }

  const onChange = () => {
    onUpdate()
  }

  return (
    <>
      <Modal
        title={translations().series.team.updateModal.title(displayName)}
        open={showModal()}
        setOpen={setModal}
      >
        <SeriesCollaboratorEditForm
          collaborator={entry}
          onSuccess={onChange}
        />

        {canDelete() && (
          <SeriesCollaboratorDelete
            collaborator={entry}
            onSuccess={onChange}
          />
        )}
      </Modal>

      <div class={styles.collaboratorEntry} title={role.label}>
        <div class={styles.about}>
          <div class={roleClasses}>
            <span>{role.label}</span>
          </div>

          <div class={styles.name}>
            {displayName}
          </div>
        </div>

        {(canUpdate || isSelf) && (
          <SmallButton
            className={styles.action}
            onClick={onEdit}
          >
            {translations().general.actions.edit}
          </SmallButton>
        )}
      </div>
    </>
    
  )
}

export default SeriesCollaboratorEntry
